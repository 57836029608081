import React from 'react';
import cn from 'classnames';
import InstagramIcon from '../../../public/icons/instagram.svg';
import FacebookIcon from '../../../public/icons/facebook-foo.svg';
import TwitterIcon from '../../../public/icons/twitter.svg';
import styles from './Footer.module.scss';
import routes, { routesPatterns } from '../../helpers/routes';
import Link from '../Link';
import ContentLayout from '../Layout/ContentLayout';
import Emmiter from '../../lib/emmiter';
import EVENTS from '../../constants/EVENTS';

const Footer = (props) => {
  const { searchPage } = props;
  const handleClickFarmerRegistration = () => {
    Emmiter.emit(EVENTS.OPEN_MODAL_FARMER_REGISTRATION);
  };

  return (
    <div>
      {searchPage ? null : (
        <div className={cn(styles.footer, 'footer')}>
          <ContentLayout>
            <div className={styles.links}>
              <div className={styles.linksInner}>
                <div className={styles.InnerItemLogo}>
                  <Link href={routes.root} className={styles.logoLink}>
                    <img src="/icons2/logo-company__footer.svg" alt="logoCompany"></img>
                  </Link>
                </div>

                <div className={cn(styles.item, styles.copyrite)}>© 2023 Все права защищены</div>

                <div className={styles.InnerItem}>
                  <a
                    href="https://instagram.com"
                    target="_blank"
                    className={styles.socialLinks}
                    rel="noreferrer"
                  >
                    <InstagramIcon className={styles.socialIcon} />
                  </a>
                  <a
                    href="https://www.facebook.com"
                    target="_blank"
                    className={styles.socialLinks}
                    rel="noreferrer"
                  >
                    <FacebookIcon className={styles.socialIcon} />
                  </a>
                  <a
                    href="https://twitter.com"
                    target="_blank"
                    className={styles.socialLinks}
                    rel="noreferrer"
                  >
                    <TwitterIcon className={styles.socialIcon} />
                  </a>
                </div>
              </div>

              <ul className={styles.footerList}>
                <li className={styles.listTitle}>для клиентов</li>
                <li className={styles.listItem}>
                  <Link href={routes.faq} className={styles.itemLink}>
                    Вопрос-ответ
                  </Link>
                </li>
                <li className={styles.listItem}>
                  <Link href={routes.deliveryRegistration}>Условия доставки</Link>
                </li>
                <li className={styles.listItem}>
                  <Link href={routesPatterns.flatpage} as={routes.flatpage('return-products')}>
                    Возврат продукции
                  </Link>
                </li>
              </ul>

              <ul className={styles.footerList}>
                <li className={styles.listTitle}>ПАРТНЕРЫ</li>
                <li className={styles.listItem}>
                  <Link href={routes.farmers} className={styles.itemLink}>
                    Наши фермеры
                  </Link>
                </li>
                <li className={styles.listItem} onClick={handleClickFarmerRegistration}>
                  Стать фермером
                </li>
              </ul>

              <ul className={styles.footerList}>
                <li className={styles.listTitle}>
                  <Link href={routesPatterns.flatpage} as={routes.flatpage('about-us')}>
                    О нас
                  </Link>
                </li>
                <li className={styles.listItem}>
                  <Link
                    className={styles.itemLink}
                    href={routesPatterns.flatpage}
                    as={routes.flatpage('about-company')}
                  >
                    О компании
                  </Link>
                </li>
                <li className={styles.listItem}>
                  <Link
                    className={styles.itemLink}
                    href={routesPatterns.flatpage}
                    as={routes.flatpage('contacts')}
                  >
                    Контакты
                  </Link>
                </li>
              </ul>
            </div>
            <div className={styles.footerPolicyBlock}>
              <a href="/" className={styles.footerPrivacyPolicy}>
                Политика конфиденциальности
              </a>
              <a href="/" className="styles.footerDataProcessing">
                Обработка персональных данных
              </a>
            </div>
            <div className={styles.line} />
          </ContentLayout>
        </div>
      )}
    </div>
  );
};
export default Footer;
