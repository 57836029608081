import React, { forwardRef } from 'react';
import cn from 'classnames';
import styles from './button.module.scss';

const Button = forwardRef((props, ref) => {
  const {
    className,
    children,
    onClick,
    size,
    variant,
    rounded,
    fluid,
    circle,
    tag,
    type = 'button',
    increased,
    uppercase,
    data,

    ...rest
  } = props;

  const handleClick = (event) => {
    if (onClick) onClick(event, data);
  };

  const buttonClassNames = cn(styles.button, {
    [className]: Boolean(className),
    [styles[variant]]: Boolean(variant),
    [styles[size]]: Boolean(size),
    [styles.rounded]: rounded,
    [styles.fluid]: fluid,
    [styles.circle]: circle,
    [styles.increased]: increased,
    [styles.uppercase]: uppercase,
  });

  return React.createElement(
    tag,
    {
      className: buttonClassNames,
      type,
      data,
      onClick: handleClick,
      ref,
      ...rest,
    },
    children,
  );
});

Button.displayName = 'Button';

Button.defaultProps = {
  className: null,
  onClick: null,
  rounded: false,
  fluid: false,
  circle: false,
  uppercase: false,
  increased: false,
  variant: 'primary',
  tag: 'button',
  size: null,
  data: null,
};

export default Button;
