import React from 'react';
import PropTypes from 'prop-types';
import NextLink from 'next/link';
import classNames from 'classnames';
import styles from './Link.module.scss';

const Link = ({ href, as, children, className, useLinkButton, ...rest }) => {
  const linkClassNames = classNames(styles.link, className);

  return (
    <NextLink href={href} as={as} passHref prefetch={false} legacyBehavior>
      {useLinkButton ? (
        children
      ) : (
        <a className={linkClassNames} {...rest}>
          {children}
        </a>
      )}
    </NextLink>
  );
};

Link.defaultProps = {
  as: null,
  className: null,
  useLinkButton: false,
};

Link.propTypes = {
  href: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.array, PropTypes.string]).isRequired,
  as: PropTypes.string,
  className: PropTypes.string,
  useLinkButton: PropTypes.bool,
};

export default Link;
